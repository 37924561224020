import "./index.scss"

import React from "react";
import Highlighter from 'react-highlight-words';

type textSymbol = "point" | "number"

type Props = {
  title?: string,
  des?: string,
  li?: string[],
  symbol?: textSymbol,
  bottom?: string,
  sunTitleTexts?: { title: string, li: string [] } []
  highlight?: string[],
  style?: React.CSSProperties
}

export const Content = React.memo((props: Props) => {
  const {title, des, style, li, symbol, bottom, sunTitleTexts, highlight} = props;

  const item = (e: string, k: number) => {
    const Highlight = <Highlighter
      searchWords={highlight ?? []}
      autoEscape={true}
      textToHighlight={e}
      highlightStyle={{backgroundColor: 'white', fontSize: 'calc(var(--text-size) + 1px)'}}
    />

    return <span key={k}>
                {symbol === "point" ? <span style={{display: "flex", alignItems: "start", gap: "5px"}}>
                     <span style={{
                       display: "flex",
                       minWidth: "6px",
                       minHeight: "6px",
                       borderRadius: "50%",
                       background: "var(--border-color)",
                       marginTop: "3px"
                     }}/>{Highlight}
                </span> : <span>
                    {`${k + 1}.`} {Highlight}
                </span>}
            </span>
  }

  return <div className="content" style={style}>
    <span className={"title"}>{title}</span>
    {des && <span className="des">{des}</span>}
    {li && <span className="li">{li.map((e, k) => item(e, k))}</span>}

    {sunTitleTexts && sunTitleTexts.map((e, k) => {
      return <div style={{display: 'flex', flexDirection: "column", gap: "10px"}} key={k}>
        <span className="title1">{e.title}</span>
        <span style={{marginLeft:"10px"}} className="li">{e.li.map((e, k) => {
          return item(e, k)
        })}</span>
      </div>
    })}

    {bottom && <span className="des">{bottom}</span>}
  </div>
})

export const Content1 = React.memo((props: Props & { events?: Function[] }) => {
  const {title, des, li, symbol, bottom, sunTitleTexts, highlight, events,} = props;

  const item = (e: string, k: number) => {
    const Highlight = <Highlighter
      searchWords={highlight ?? []}
      autoEscape={true}
      textToHighlight={e}
      highlightStyle={{
        borderBottom: "1px solid var(--border-color)",
        background: "black",
        color: "var(--border-color)", cursor: "pointer"
      }}
      onClick={() => events && events[k] && events[k]()}
    />

    return <span style={{marginBottom: '10px'}} key={k}>
                {symbol === "point" ? <span style={{display: "flex", alignItems: "start", gap: "5px"}}>
                     <span style={{
                       display: "flex",
                       minWidth: "6px",
                       minHeight: "6px",
                       borderRadius: "50%",
                       background: "var(--border-color)",
                       marginTop: "3px"
                     }}/>{Highlight}
                </span> : <span>
                    {`${k + 1}.`} {Highlight}
                </span>}
            </span>
  }

  return <div className="content">
    <span className={"title"}>{title}</span>
    {des && <span className="des">{des}</span>}
    {li && <span className="li">{li.map((e, k) => item(e, k))}</span>}

    {sunTitleTexts && sunTitleTexts.map((e, k) => {
      return <div style={{display: 'flex', flexDirection: "column", gap: "10px"}} key={k}>
        <span className="title1">{e.title}</span>
        <span className="li">{e.li.map((e, k) => {
          return item(e, k)
        })}</span>
      </div>
    })}

    {bottom && <span className="des">{bottom}</span>}
  </div>
})

