import {idlFactory} from "../did/dashboard/dashboard.did";
import {getActor} from "../utils/Actor";
import {MinerInfo} from "../did/dashboard/dashboard";
import {Principal} from "@dfinity/principal";

const cid = "pgktv-biaaa-aaaak-qlh3a-cai"

class Dashboard {
  async getActor() {
    return await getActor.createActor(
      idlFactory,
      cid
    );
  }

  async getNoIdentityActor() {
    return await getActor.noIdentityActor(
      idlFactory,
      cid
    );
  }

  async dashboard(): Promise<[bigint, bigint,bigint][]> {
    const actor = await this.getNoIdentityActor()
    try {
      const res = await actor.dashboard() as Array<[bigint, bigint,bigint]>
      // console.log(res)
      return res
    } catch (e: any) {
      console.log("dashboard", e)
      throw e.message
    }
  }

  async get_mined_blocks(cid: Principal) {
    const actor = await this.getNoIdentityActor()
    try {
      const res = await actor.get_mined_blocks(cid) as number
      return res
    } catch (e: any) {
      console.log("get_mined_blocks", e)
      throw e.message
    }
  }

  async top_miners() {
    const actor = await this.getNoIdentityActor()
    try {
      const res = await actor.top_miners() as Array<MinerInfo>
      // console.log("top_miners", res)
      return res
    } catch (e: any) {
      console.log("top_miners", e)
      throw e.message
    }
  }
}

export const dashboardApi = new Dashboard();
