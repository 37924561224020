import "./index.scss"
import React, {useEffect, useState} from "react";
import {CommonButton, Loading} from "../Button";
import {NumberInput} from "../Treasury";
import {parseUnits} from "ethers";
import {tokenApi} from "../../api/token";
import {message} from "antd";
import {useInit} from "../../utils/useInit";
import {useMinersStore} from "../../redux/features/miners";
import {Principal} from "@dfinity/principal";
import {MainCai, newError} from "../../api/main";
import {minerApi} from "../../api/miner";
import {shortenString} from "../../utils/util";
import {Copy} from "../Mining";
import useWindowSize from "../../hook/Resize";
import {useDashboardStore} from "../../redux/features/dashboard";


export const Staking = React.memo(() => {
  const [address, setAddress] = React.useState("")
  const [amount, setAmount] = React.useState(0)
  const {setLoading, refresh} = useInit()
  const {stakingBalances, miners} = useMinersStore()
  const {blockChain} = useDashboardStore()
  const {width} = useWindowSize();
  const [ids, setIds] = useState<Principal[] | undefined>()
  const [amountArr, setAmountArr] = useState<(number | newError)[]>()
  const [withdrawDelay, setWithdrawDelay] = useState<(number | newError)[]>()
  const [withdrawFee, setWithdrawFee] = useState<number[] | undefined>()

  useEffect(() => {
    const getWithdrawFee = async () => {
      if (!ids) return
      const promises: Promise<number>[] = []
      for (let i = 0; i < ids.length; i++) {
        const api = minerApi(ids[i].toString())
        promises.push(api.get_withdraw_fee())
      }
      const fees = await Promise.all(promises)
      setWithdrawFee(fees)
    }
    getWithdrawFee()
  }, [ids]);


  useEffect(() => {
    getStakingMiners()
  }, [stakingBalances, miners]);

  const getStakingMiners = async () => {
    try {
      if (!miners || !stakingBalances) return
      const ids: Principal[] = []
      const amountArr: (number | newError)[] = []
      const delayPromises: Promise<number>[] = []
      for (let i = 0; i < stakingBalances.length; i++) {
        if (typeof stakingBalances[i] === "number") {
          if (Number(stakingBalances[i]) > 0) {
            ids.push(miners[i])
            const api = minerApi(miners[i].toString())
            delayPromises.push(api.get_withdraw_delay())
            amountArr.push(stakingBalances[i])
          }
        }
      }
      setIds(ids)
      setAmountArr(amountArr)
      const delays = await MainCai.handlePromises(delayPromises)
      setWithdrawDelay(delays)
    } catch (e) {
      console.error(e)
    }
  }

  const staking = async () => {
    if (amount <= 0) return
    const isConfirm = window.confirm(`Are you sure you want to stake ${amount} ICPS?`)
    if (!isConfirm) return
    setLoading(true)
    let newAmount: bigint = parseUnits(amount + "", 8)
    newAmount = newAmount - BigInt(0.01 * 1e8) // -fee
    try {
      await tokenApi.icrc1_transfer(address.trim(), newAmount)
      message.info("Please wait for approximately 1 hour for the process to complete.")
    } catch (e: any) {
      message.error(e.message)
    } finally {
      getStakingMiners()
      setLoading(false)
    }
  }

  const withdraw = async (cid: string, balance: number | string) => {
    const isConfirm = window.confirm("Are you sure you want to withdraw?")
    if (!isConfirm) return
    if (typeof balance === "number") {
      if (balance <= 2_000_000) {
        message.warning("The balance is too low to withdraw")
        return
      }
    }
    setLoading(true)
    try {
      const api = minerApi(cid)
      await api.withdraw_token()
      message.info("Please wait for 100 blocks for the process to complete.")
    } catch (e: any) {
      if (e?.includes && e.includes("withdraw finalizing")) {
        message.success("Withdrawal request successful,Processing...")
      } else if (e?.includes && e.includes("out of cycles")) {
        message.warning(`Canister ${cid} is out of cycles, please top up cycles`)
      } else
        message.info(e)
    } finally {
      setLoading(false)
    }
  }

  const Refresh = async () => {
    setIds(undefined)
    refresh()
  }

  return <div style={{flexDirection: width < 900 ? "column" : "row"}} className={"staking"}>
    <div style={{width: width < 900 ? "100%" : "auto"}} className={"staking_modal"}>
      <div className={"input_wrap"}>
        <input type="text" placeholder={"Miner Canister ID"} onChange={e => setAddress(e.target.value)}/>
        <NumberInput placeholder={"icps amount"} setAmount={setAmount}/>
      </div>
      <div style={{display: 'flex', width: "100%", justifyContent: "center"}}>
        <CommonButton style={{width: "100%", justifyContent: "center"}} text={"start staking"}
                      handleClick={staking}
                      needLoading={true}/>
      </div>
    </div>
    {/*<WithdrawTip setOpen={()=>{}} open={true}/>*/}
    <div style={{width: width < 900 ? "100%" : "auto"}} className={"staking_miners"}>
      <h3>
        Staking Miner
        <CommonButton style={{justifyContent: "center"}} text={"refresh"} handleClick={Refresh} needLoading={true}/>
      </h3>
      <table>
        <tbody>
        {ids ? ids.map((v, k) => {
          const amountText = amountArr ? (typeof amountArr[k] === "number" ? (Number(amountArr[k]) / 1e8) + "ICPS" : amountArr[k]) : "-/-"
          const delayHeight = withdrawDelay ? withdrawDelay[k] : "-/-"
          let delayText: any = ""
          if (blockChain) {
            if (delayHeight !== 0) {
              if (typeof delayHeight === "number") {
                if (delayHeight <= Number(blockChain.block_height)) {
                  delayText = ""
                } else {
                  delayText = `Withdrawal pending: ${delayHeight - Number(blockChain.block_height)} blocks remaining`
                }
              } else
                delayText = delayHeight
            }
          }
          return (
            <tr key={k} className={"row_item"}>
              <td style={{display: "flex", flexDirection: "column"}}>
                <span style={{display: "flex", alignItems: 'center'}}>
                  {shortenString(v.toString(), 10)} <Copy text={v.toString()}/>
                </span>
                <span style={{wordBreak: 'break-all', overflowWrap: "break-word"}}>
                    {delayText}
                </span>
              </td>
              <td style={{wordBreak: "keep-all"}}>{amountText}</td>
              <td>
                <CommonButton
                  style={{padding: "6px", wordBreak: "keep-all", justifyContent: "center", marginBottom: '5px'}}
                  text={"withdraw"}
                  handleClick={() => withdraw(v.toString(), amountArr ? amountArr[k] : 0)}
                  needLoading={true}
                />
                {withdrawFee ? withdrawFee[k] === -1 ? null : <>
                  {"Fee: " + (withdrawFee[k] / 1e8).toFixed(2) + "$ICPS"}
                </> : "-/-"}
              </td>
            </tr>
          )
        }) : <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
          <Loading show={true}/>
        </div>}
        </tbody>
      </table>

    </div>
  </div>
})
