import React from "react"
import {Content, Content1} from "../Content";
import "./index.scss"
import {upgradeAmount} from "../Mining";

export const Home = () => {
  return <>
    <Content1 title={"information"}
              li={[
                "ICPS Ledger: ypkpu-uaaaa-aaaam-adihq-cai",
                "ICPS Canister: 2co3e-qyaaa-aaaao-qaaja-cai",
                "Trade: https://app.icpswap.com",
                "Token Standard: ICRC-2",
              ]} symbol={"point"}
              highlight={["ypkpu-uaaaa-aaaam-adihq-cai", "2co3e-qyaaa-aaaao-qaaja-cai", "https://app.icpswap.com"]}
              events={[() => {
                window.open("https://dashboard.internetcomputer.org/canister/ypkpu-uaaaa-aaaam-adihq-cai")
              }, () => window.open("https://dashboard.internetcomputer.org/canister/2co3e-qyaaa-aaaao-qaaja-cai"),
                () => window.open("https://app.icpswap.com/swap?output=ypkpu-uaaaa-aaaam-adihq-cai")]}
    />
    <Content title={"What is ICPS.Fun?"}
             des={"ICPS.fun is the first PoUSW (Proof of Useful Stake and Work) mining platform running on the ICP blockchain. It combines classic Proof of Stake with innovative Proof of Work, offering a unique mining approach. Leveraging the Internet Computer's architecture, ICPS.fun delivers an efficient and low-cost mining experience, enabling users to participate easily and earn substantial block rewards."}
    />
    <Content title={"How does ICPS.Fun work?"}
             des={"ICPS.fun operates on the PoUSW model, merging the benefits of Proof of Stake (PoS) and Proof of Work (PoW) with a task-driven system, creating a highly efficient and fair mining process:"}
             li={["Token Rewards: Each block reward is 10 ICPS.",
               "Total Supply: The total supply of ICPS is 10 million.",
               "Halving Cycle: Block rewards halve every 150,000 blocks, with the first halving occurring approximately 69 days after the start.",
               "Target Block Speed: On average, 1 block is produced every 40 seconds.",
               "No Pre-Mining: All token rewards are obtained through mining, ensuring fairness."]}
             symbol={"point"}
    />
    <Content title={"Mining Details of ICPS.fun"}
             des={"Mining on ICPS.fun is simple and efficient. At the start of each mining cycle, the main node issues challenges to all miners. Miners solve these challenges by calculating hash values to earn block rewards. Mining difficulty adjusts automatically to maintain an average block production time of around 40 seconds."}
             sunTitleTexts={[{
               title: "Basic Mining Process",
               li: ["Initial Parameter on Miner Creation: Hash Power: When a miner is created, a random number hash_power is generated as the miner’s initial hash power, determined by a normal distribution range of 3500-4100, with 3800 as the mean.",
                 "Random Number Generation: The miner’s canister continuously generates random numbers (random_number) used in the mining process. Each calculation is based on the current random number and hash power, repeatedly attempting mining."
                 , "Stake Mechanism: Each mining machine can stake ICPS. Once staking is activated, a staking_number is generated, and the mining power begins to increase after 100 blocks. Starting from block height 2000, the staking_power is set to 5 and decreases by 10% every 1000 blocks thereafter, until the staking_power reaches 1, where it will remain unchanged. If a withdraw is performed, all staked ICPS will be automatically returned to the user’s address after 100 blocks, and the staking_number will reset to 0." +
                 "\nstaking_number = icps_number * staking_power"]
             }, {
               title: "Calculation Formula and Rewards",
               li: [`Calculation Formula: During mining, the miner calculates the score points using the formula:\n points = (hash_power + staking_number) * random_number`,
                 "Block Generation Conditions and Rewards Distribution: Each epoch lasts 40 seconds. At the end of each epoch, 7 ICPS are distributed to a random canister selected from the top 50% of those that submitted the highest points during that epoch, ensuring fairness and sustainability in mining. Additionally, the remaining 3 ICPS are used for a lottery among the most recently created 50 miners, with 3 miners randomly selected, each receiving 1 ICPS. The stake amount cannot exceed 50% of the miner's power, maintaining a balanced and fair mining process.",
                 "Block Generation Probability: The higher the initial hash_power of the mining machine and the greater the staked staking_number, the higher the probability of mining success.",
                 "Dynamic Threshold: The threshold adjusts dynamically based on the number of miners and network conditions to ensure fairness.",
                 "Halving Cycle: Block rewards halve periodically to simulate the classic halving model.",
                 "Power Calculations Example: For instance, if initial power is 3800 and 100 ICPS are staked, the power will change as follows:" +
                 "\n\n  •From block 0 to block 2000:" +
                 "\n    •power = 3800 + 1 * 100 = 3900" +
                 "\n\n  •From block 2000 to block 3000:" +
                 "\n    •power = 3800 + 5 * 100 = 4300" +
                 "\n\n  •From block 3000 to block 4000:" +
                 "\n    •power = 3800 + (5 * 0.9) * 100 = 4250" +
                 "\n\n  •This continues until the staking weight decreases to 1, at which point:" +
                 "\n    •power = 3800 + 1 * 100 = 3900"]
             }]} symbol={"point"}
             highlight={["points = (hash_power + staking_number) * random_number",
               "staking_number = icps_number * staking_power",
               "power = 3800 + 1 * 100 = 3900",
               "power = 3800 + 5 * 100 = 4300",
               "power = 3800 + (5 * 0.9) * 100 = 4250"]}
    />

    <Content title={"Key Concepts for Understanding Mining"}
             li={["Mining Power and Success: ICPS mining is similar to Bitcoin mining; the higher the power, the greater the chances of mining a block and earning rewards. As more miners join the network, overall difficulty increases, requiring more power to maintain or improve success rates.\n" +
             "    \n" +
             "In simple terms:" +
             "\n •Higher Power = Better odds of mining a block." +
             "\n •Increased Difficulty = As the number of miners grows, the mining process becomes harder, requiring more computing power to secure rewards." +
             "\n\nThis system incentivizes miners to boost their power to stay competitive, just like in BTC mining.",
               "Staking for Stability: Staking is introduced to reduce selling pressure on ICPS. By staking their tokens, holders are encouraged to lock up their ICPS rather than sell, which helps stabilize the price. The aim is to enhance the utility of ICPS, creating a flywheel effect—where the token's use cases and demand increase over time, driving up its value.",
               "Fair Competition: The platform's use of a power decay mechanism and random selection from the top 50% of miners ensures fairness. This setup helps balance competition by preventing any single miner from dominating due to high power alone, allowing even smaller miners a chance to win.",
               "Increasing Power: Besides staking, miners can also increase their power by creating new miners. Each new miner starts with an initial hash power, which might be higher than their current one. By adding more miners, they can potentially achieve greater overall mining power alongside staking.",
               "Ways to Earn More ICPS: There are two primary methods to obtain more ICPS. The first is to create more new miners, as each block contains 3 ICPS that are randomly awarded through a lottery to the most recently created 50 miners. The second is to purchase additional ICPS to stake, thereby increasing the miner's power."]}
             symbol={"point"}
    />

    <Content title={"Mining Costs"}
             li={["Mining Cost: Creating a miner now costs 1.2 ICP, with 0.2 ICP directed to the treasury, which will add funds to the liquidity pool daily.",
             ]}
             symbol={"point"}
    />
    <Content title={"How to Start Mining"}
             li={["Access Account ID: Visit ICPS.fun, navigate to “Vault” to find your Account ID.",
               "Fund Your Account: Deposit ICP into your Account ID.",
               "Activate Mining: Go to the Mining page and click “New Miner” to start mining ICPS."
             ]}
             symbol={"number"}
    />
    <Content title={"Staking for Boost your Miner"}
             li={["Acquire ICPS: Get ICPS from ICPSwap or through mining rewards.",
               "Check ICPS Holdings: View your ICPS balance in Vault.",
               "Copy Canister ID: Copy the Canister ID from the Mining page that you wish to Boost.",
               "Initiate Staking: Paste the Canister ID on the Staking page, enter the desired ICPS amount (not exceeding 50% of the Miner’s power), click Stake, and wait for approximately 1 hour for confirmation"
             ]}
             symbol={"number"}
    />
    <Content title={"Withdraw Staked ICPS"}
             li={["Locate Canister ID: On the Staking page, find the Canister ID to Withdraw from.",
               "Process Withdraw: Click Withdraw and wait for 100 blocks for ICPS to be released, with a fee sent to the treasury for liquidity enhancement, decreasing based on the staking duration. The longer the staking duration, the lower the withdrawal fee required:" +
               "\n  a. Initial Fee: 20% for the first 15,120 blocks (approximately one week)." +
               "\n  b. Decreasing Fee: The fee decreases linearly with each block, specifically by 0.0001043% per block." +
               "\n  c. Final Fee: 1% after 181,440 blocks (approximately 12 weeks).",
               "Verify Balance: Go back to Vault to check your updated ICPS balance."
             ]}
             symbol={"number"}
    />
    <Content title={"Upgrade Option"}
             des={`Existing miners can upgrade to the new miner (with the initial power range of 3500-4100) by spending ${upgradeAmount} ICP, recharging 2T cycle.`}
             li={["Select the Miner: Find the miner you wish to upgrade.",
               "Check Cycle Balance: Ensure that the miner has sufficient cycle balance.",
               "Click Upgrade: Click the \"Upgrade\" button to initiate the process."]}
             symbol={"number"}
    />
    <Content
      title={"New Miner Benefits"}
      des={"The new miner offers lower cycle consumption, faster computation speeds, and increased calculation iterations compared to the previous model.\n" +
        "\n" +
        "Join ICPS.fun and experience **The Next Generation of Fair Mining** journey—We stand for fairness. Pure and simple-\n" +
        "\n" +
        "These parameters ensure a more balanced and sustainable model, guaranteeing fair rewards to participants without causing excessive token supply inflation, thereby supporting the platform’s long-term viability."}
    />
  </>
}
