export const idlFactory = ({ IDL }) => {
  const SwapArgs = IDL.Record({
    'amountIn' : IDL.Text,
    'zeroForOne' : IDL.Bool,
    'amountOutMinimum' : IDL.Text,
  });
  const Error = IDL.Variant({
    'CommonError' : IDL.Null,
    'InternalError' : IDL.Text,
    'UnsupportedToken' : IDL.Text,
    'InsufficientFunds' : IDL.Null,
  });
  const Result = IDL.Variant({ 'ok' : IDL.Nat, 'err' : Error });
  const SwapPool = IDL.Service({
    'quote' : IDL.Func([SwapArgs], [Result], ['query']),
  });
  return SwapPool;
};
export const init = ({ IDL }) => { return []; };
