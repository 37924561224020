import idlFactory from "../did/Ledger/ledger.did";
import {ICP, TransferResult} from "../did/Ledger/ledger";
import {Principal} from "@dfinity/principal";
import {getActor} from "../utils/Actor";
import {getToAccountIdentifier, getUint8ArrayFromHex} from "../utils/util";
import {requestInterceptor} from "./index";

const ledger = "ryjl3-tyaaa-aaaaa-aaaba-cai";

class Ledger {
  async getActor() {
    return await getActor.createActor(
      idlFactory,
      ledger
    );
  }

  async getNoIdentityActor() {
    return await getActor.noIdentityActor(
      idlFactory,
      ledger
    );
  }

  async account_balance(accountID: string) {
    if (!accountID) return 0
    const tmp = getUint8ArrayFromHex(accountID);
    const accountIdentifier = Array.from(tmp);
    try {
      const res = (await (
        await this.getNoIdentityActor()
      ).account_balance({account: accountIdentifier})) as ICP;
      return Number(res.e8s) / 1e8;
    } catch (e) {
      throw e
    }
  }

  async real_account_balance(principal: Principal): Promise<ICP> {
    try {
      const tmp = getUint8ArrayFromHex(
        getToAccountIdentifier(principal)
      );
      const accountIdentifier = Array.from(tmp);
      return (await (
        await this.getNoIdentityActor()
      ).account_balance({account: accountIdentifier})) as ICP;
    } catch (e) {
      throw e
    }
  }

  async getBalance(account: string) {
    const tmp = getUint8ArrayFromHex(account)
    const res = (await (
      await this.getNoIdentityActor()
    ).account_balance({account: tmp})) as any;
    console.log(res)
  }

  @requestInterceptor
  async transfer(
    to: string,
    memo: bigint,
    amount: bigint
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const tmp1 = getUint8ArrayFromHex(to);
        const _to = Array.from(tmp1);
        const fee: ICP = {e8s: BigInt(10000)};
        const _amount = amount - BigInt(10000);
        const res = await (
          await this.getActor()
        ).transfer({
          to: _to,
          fee: fee,
          memo: memo,
          from_subaccount: [],
          created_at_time: [],
          amount: {e8s: _amount},
        }) as TransferResult
        resolve(res);
      } catch (e) {
        reject(e);
      }
    });
  }
}

export const LedgerApi = new Ledger();
