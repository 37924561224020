import React, {useEffect, useState} from "react"
import "./index.scss"
import {CommonButton} from "../Button";
import {useInit} from "../../utils/useInit";
import {LedgerApi} from "../../api/ledger";
import {tokenApi} from "../../api/token";
import {message} from "antd";
import {parseUnits} from "ethers";
import {Copy} from "../Mining";
import {mainApi} from "../../api/main";
import {Principal} from "@dfinity/principal";
import {Content} from "../Content";
import {shortenString} from "../../utils/util";
import {TowColumn} from "../Dashboard";

export const Treasury = () => {
  const {account, principal, setLoading, referCode} = useInit()
  const [icpBalance, setIcpBalance] = React.useState<number>()
  const [icpsBalance, setIcpsBalance] = React.useState<number>()
  const [codeUser, setCodeUser] = React.useState<Principal[]>()

  const checkPrincipalBalance = async (who: Principal, to: string) => {
    try {
      const res = await LedgerApi.real_account_balance(who)
      if (res.e8s > BigInt(10000)) {
        await LedgerApi.transfer(to, BigInt(0), res.e8s)
        getBalance()
      }
    } catch (e) {
      throw e
    }
  }

  const getBalance = async () => {
    try {
      if (!principal || !account) return
      checkPrincipalBalance(principal, account)
      setLoading(true)
      tokenApi.icrc1_balance_of(principal).then(e => setIcpsBalance(e / 1e8))
      const res = await LedgerApi.account_balance(account)
      setIcpBalance(res)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }


  useEffect(() => {
    getBalance()
  }, [principal, account])

  useEffect(() => {
    referCode && mainApi.get_referral_users(referCode).then(e => {
      console.log(e)
      setCodeUser(e)
    })
  }, [referCode]);

  return <div className={"treasury"}>
    <div style={{flex: "1",marginBottom:"30px"}}>
      <div className={"treasury_head"}>
        <div style={{display: 'flex'}}>
          <CommonButton text={"Refresh"} handleClick={getBalance} needLoading={true}/>
        </div>
        <div className="treasury_head_body">
          <div className={"address"}>
            <span className={"title0"}>addresses</span>
            <span className={"title1"}>Account Id:</span>
            <span className={"address_content"}>{shortenString(account ?? "", 50)}
              <Copy text={account ?? ""}/>
          </span>
            <span className={"title1"}>Principal:</span>
            <span className={"address_content"}>{shortenString(principal?.toString() ?? "", 50)}
              <Copy text={principal ? principal?.toString() : ""}/>
          </span>
          </div>
          <div className={"balance"}>
            <span className={"title0"}>balances</span>
            <span className={"title1"}>ICPS: {icpsBalance === undefined ? "-/-" : icpsBalance}</span>
            <span className={"title1"}>ICP: {icpBalance === undefined ? "-/-" : icpBalance}</span>
          </div>
          <div className={"refer"}>
            <span className={"title0"}>Referral Code</span>
            <span className={"address_content"}>{referCode ?? "-/-"}
              <Copy text={referCode ?? ""}/>
          </span>
          </div>
        </div>
      </div>
      <SendModal balances={{icpBalance: icpBalance ?? 0, icpsBalance: icpsBalance ?? 0}} getBalance={getBalance}/>
    </div>
    {codeUser && codeUser.length > 0 && <div style={{flex: "1"}}>
      <Content title={"Miners You Referred"}/>
      <table className={"code_users"} style={{marginTop:"20px"}}>
        <thead>
        <tr>
          <th>{"Miner Canister Id"}</th>
        </tr>
        </thead>
        <tbody>
        {codeUser?.map((v, k) => {
          return <tr key={k}>
            <td>{v.toString()}</td>
          </tr>
        })}
        </tbody>
      </table>
    </div>}
  </div>
}


const SendModal = React.memo(({getBalance, balances}: {
    getBalance: Function,
    balances: { icpBalance: number, icpsBalance: number }
  }) => {
    const [token, setToken] = useState<"ICP" | "ICPS">("ICPS")
    const [address, setAddress] = React.useState("")
    const [amount, setAmount] = React.useState(0)
    const {setLoading} = useInit()

    const send = async () => {
      if (!address || amount <= 0) return 0
      const isConfirm = window.confirm(`Are you sure to send? (${amount + " " + token})`)
      if (!isConfirm) return 0
      setLoading(true)
      const newAddress = address.trim()
      let newAmount: bigint = parseUnits(amount + "", 8)
      try {
        if (token === "ICPS") {
          if (newAddress.length !== 63) return message.error("Please enter a valid Principal ID")
          newAmount = newAmount - BigInt(0.01 * 1e8) // -fee
          if (newAmount > BigInt(Math.floor(balances.icpsBalance * 1e8))) return message.error("ICPS balance mismatch")
          await tokenApi.icrc1_transfer(newAddress, newAmount)
        } else {
          if (newAddress.length !== 64) return message.error("Please enter a valid Account ID")
          newAmount = newAmount - BigInt(10000) // -fee
          if (newAmount > BigInt(Math.floor(balances.icpBalance * 1e8))) return message.error("ICP balance mismatch")
          await mainApi.withdraw_icp(newAddress, newAmount)
        }
      } catch (e: any) {
        message.error(e.message)
      } finally {
        setLoading(false)
        getBalance()
      }
    }
    return <div className={"send_modal"}>
      <div className={"title"}>
      <span onClick={() => setToken("ICPS")}
            style={{borderBottom: token === "ICPS" ? "1px solid var(--border-color)" : "1px solid black"}}>ICPS</span>
        <span onClick={() => setToken("ICP")}
              style={{borderBottom: token === "ICP" ? "1px solid var(--border-color)" : "1px solid black"}}>ICP</span>
      </div>
      <div className={"input_wrap"}>
        <input type="text" placeholder={token === "ICPS" ? "Principal" : "Account ID"}
               onChange={e => setAddress(e.target.value)}/>
        <NumberInput setAmount={setAmount}/>
      </div>
      <div style={{display: 'flex'}}>
        <CommonButton text={"Send"} handleClick={send} needLoading={true}/>
      </div>
    </div>
  }
)

export const NumberInput = React.memo(({setAmount, placeholder}: { setAmount: Function, placeholder?: string }) => {
  return <input className={"number_input"} type="number" min={0} placeholder={placeholder ?? "0.00"} onChange={e => {
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, '');
    value = value.replace(/^0+(?!\.|$)/, '');
    if ((value.match(/\./g) || []).length > 1) {
      value = value.replace(/\.(?=.*\.)/, '');
    }
    if (value.startsWith('-')) {
      value = value.substring(1);
    }
    if (parseFloat(value) < 0) {
      value = '';
    }
    e.target.value = value;
    setAmount(+value)
  }}/>
})
