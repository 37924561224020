import {createSlice} from "@reduxjs/toolkit"
import {RootState} from "../store"
import {useAppSelector} from "../hook"
import {Principal} from "@dfinity/principal";
import {Blockchain} from "../../did/main/main";

type MinerData = {
  miners?: Principal[]
  cycleBalance?: (number | string)[]
  blockMined?: number[]
  power?: (number | string)[],
  stakingBalances?: number[],
  isNew?: boolean[]
  stakingPoints?: number[]
}

const initialState: MinerData = {}
export const minersSlice = createSlice({
  name: "miners",
  initialState,
  reducers: {
    update: (state, action: { type: string, payload: MinerData }) => {
      return {...state, ...action.payload}
    },
  },
})

const {update} = minersSlice.actions
const miners = (state: RootState) => state.miners
export const updateMiners = async (result: MinerData) => {
  const store = await (await import("../store")).default
  store.dispatch(update(result))
}
export const useMinersStore = (): MinerData => useAppSelector(miners)
export default minersSlice.reducer
