import React from "react"
import "./index.scss"
import {useLocation, useNavigate} from "react-router-dom";
import {useInit} from "../../utils/useInit";
import useWindowSize from "../../hook/Resize";


export const NavPanel = React.memo(() => {
  const location = useLocation()
  const {isAuth} = useInit()
  const navigate = useNavigate()
  const route = location.pathname.split("/")[1]
  const {width} = useWindowSize();

  const navArr = React.useMemo(() => {
    return isAuth ? ["Home", "Dashboard", "Mining", "Staking", "Vault", "Roadmap"] : ["Home", "Dashboard", "Roadmap"]
  }, [isAuth])

  const handelClick = (path: string) => {
    navigate(`/${path.toLowerCase()}`)

  }

  return <div className={"NavPanel"}>
    {width > 430 && <div/>}
    <div className={"NavPanelContent"}>
      {width <= 430 ? <More api={handelClick} list={navArr}/> : navArr.map((item, index) => {
        return <span key={index}
                     style={{borderBottom: route.toLowerCase() === item.toLowerCase() ? "1px solid var(--border-color)" : "1px"}}
                     onClick={() => navigate(`/${item.toLowerCase()}`)}>{item}</span>
      })}
    </div>
    <div style={{flexDirection: width <= 430 ? "row" : "column"}} className={"NavPanel_right"}>
      <div className={"item_wrap"}>
        <span>Ticker</span>
        <span>ICPS</span>
      </div>
      <div className={"item_wrap"}>
        <span>Logo</span>
        <img src="/logo.png" alt=""/>
      </div>
    </div>
  </div>
})

const More = React.memo((props: { list: string[], api: Function }) => {
  const [open, setOpen] = React.useState(false)

  return <div className={"more_button"} onClick={() => setOpen(!open)}>
    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         p-id="4187" width="20px" height="20px">
      <path
        d="M170.666667 469.333333h682.666666a42.666667 42.666667 0 0 1 0 85.333334H170.666667a42.666667 42.666667 0 0 1 0-85.333334z m0-298.666666h597.333333a42.666667 42.666667 0 0 1 0 85.333333H170.666667a42.666667 42.666667 0 1 1 0-85.333333z m0 597.333333h512a42.666667 42.666667 0 0 1 0 85.333333H170.666667a42.666667 42.666667 0 0 1 0-85.333333z"
        fill="#1afa29" p-id="4188"></path>
    </svg>
    {open && <Dropdown {...props} setOpen={setOpen}/>}
  </div>
})


const Dropdown = React.memo(({list, api, setOpen}: { list: string[], api: Function, setOpen: Function }) => {
  return <div className={"dropdown"}>
    {list.map((item, index) => {
      return <span onClick={() => {
        api(item, index)
        setOpen(false)
      }} key={index}>{item}</span>
    })}
  </div>
})


