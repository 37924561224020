import "./index.scss"
import React from "react";
import {useInit} from "../../utils/useInit";
import {CommonButton} from "../Button";

export const Header = React.memo(() => {
  return <div className="Header">
    <div/>
    <div className={"Header_left"}>
      <h1>
        ICPS.Fun
      </h1>
      <span>
       {"The Next Generation of Fair Mining Model\n" +
         "ICP’s Consensus with PoW Mechanism (PoUSW)\n" +
         "-We stand for fairness. Pure and simple-"}
      </span>
    </div>
    <div className={"header_right"}>
      <div className={"media"}>
        <span onClick={()=>window.open("https://x.com/icps_fun")}>
          <img src="/X.jpg" alt=""/>
        </span>
        <span onClick={()=>window.open("https://t.me/+lKArLvrB9IgyYTI1")}>
          <img src="/telegram.png" alt=""/>
        </span>
      </div>
      <LoginButton/>
    </div>
  </div>
})

const LoginButton = React.memo(() => {
  const {isAuth, logIn, logOut} = useInit()

  return <CommonButton style={{wordBreak: "keep-all"}} text={isAuth ? "logout" : "login"} needLoading={false}
                       handleClick={() => isAuth ? logOut?.() : logIn?.()}/>

})
