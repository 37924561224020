import {getActor} from "../utils/Actor";
import {GetPoolArgs, PoolData, Result_3} from "../did/icpswap/icpswap";
import {idlFactory} from "../did/icpswap/icpswap.did";

const token = "4mmnk-kiaaa-aaaag-qbllq-cai";

class Icpswap {

  async getActor() {
    return await getActor.createActor(
      idlFactory,
      token
    );
  }

  async getNoIdentityActor() {
    return await getActor.noIdentityActor(
      idlFactory,
      token
    );
  }

  async getPool(args: GetPoolArgs): Promise<PoolData> {
    const actor = await this.getNoIdentityActor()
    try {
      const res = await actor.getPool(args) as Result_3
      if ("ok" in res) {
        return res.ok
      }
      throw new Error(Object.keys(res.err)[0])
    } catch (e) {
      throw e
    }
  }

}


export const swapApi = new Icpswap();
