import {Content} from "../Content";
import React from "react";

export const Roadmap = () => {
  return <>
    <Content
      des={"Big things are coming to ICPS.Fun! Here's the roadmap for our upcoming updates\n" +
        "\n" +
        "👇👇👇"}
    />
    <Content title={"Miner Dashboard: ✅"}
             des={"The miner dashboard is live, offering real-time data on miner performance, power distribution, and earnings, helping users optimize their mining strategies."}/>
    <Content title={"Withdrawal Fees Display (Next week):"}
             des={"A new feature will be added to clearly display withdrawal fees before users withdraw their staked ICPS. The fee starts at 20% and decreases to 1% over 12 weeks, ensuring transparency and helping users make informed decisions."}/>
    <Content title={"Top-up Cycle Feature (Mid October):"}
             des={"The Top-up Cycle feature will allow miners to easily top up cycles on their canisters, ensuring smooth operation."}/>
    <Content title={"Referral System (Mid October):"}
             des={"A referral system is set to launch, rewarding users for bringing in new miners, helping grow the community while earning ICPS bonuses for each successful referral."}/>
    <Content title={"ICPS Trading Rewards (Early November):"}
             des={"Traders will soon be able to earn additional ICPS tokens through trading rewards, encouraging active participation and increasing liquidity on the platform."}/>
    <Content title={"Partnerships (Ongoing):"}
             des={"We are actively exploring partnerships to integrate ICPS across multiple platforms, expanding its utility and creating more collaboration opportunities within the ecosystem."}/>
    <Content title={"Coingecko Listing (Ongoing):"}
             des={"Listing ICPS on Coingecko is a top priority to improve visibility, credibility, and market data tracking for the token, which will attract new investors and traders."}/>
    <Content title={"Multichain Integration (December):"}
             des={"ICPS is preparing to expand to multiple chains like Base, Sol, and SUI, enabling cross-chain compatibility and allowing users to interact with ICPS across different blockchain ecosystems for enhanced accessibility and liquidity."}/>
    <Content title={"Strengthening Ecosystem (December):"}
             des={"Inspired by TitanX.Win, we aim to build a strong ecosystem model, offering users more tools and opportunities. Future plans include burning ICPS through ecosystem projects, reducing the token supply and supporting price stability."}/>
    <Content title={"Community Governance (December):"}
             des={"A governance mechanism will be introduced, enabling token holders to vote on key project decisions. Token holders will also have the opportunity to vote on ICPS burns, increasing user engagement and control over the project’s direction."}/>
    <Content title={"ICPS GPU Mining (Early 2025):"}
             des={"GPU mining will be introduced, allowing miners to use powerful GPUs to boost mining speed and efficiency. AI-driven calculations may also be incorporated, which will lead to higher cycle consumption due to the intensive nature of GPU mining and AI algorithms."}/>
    <Content title={"Staking Pool (Early 2025):"}
             des={"A staking pool will be introduced, allowing users to lock their ICPS tokens to earn daily rewards, providing an alternative to mining and increasing token retention in the ecosystem."}/>
    <Content title={"SNS Launch (Early 2025):"}
             des={"We are planning to launch ICPS on the SNS (Service Nervous System), enabling further decentralization and greater community control over the ICPS platform."}/>
  </>
}
