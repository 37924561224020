export const idlFactory = ({ IDL }) => {
  const Result = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : IDL.Text });
  const Result_1 = IDL.Variant({ 'Ok' : IDL.Nat64, 'Err' : IDL.Text });
  return IDL.Service({
    'cycle_balance' : IDL.Func([], [IDL.Nat64], ['query']),
    'finalize_withdraw' : IDL.Func([IDL.Nat64], [Result], []),
    'get_canister_power' : IDL.Func([], [IDL.Float64], ['query']),
    'get_miner_version' : IDL.Func([], [IDL.Nat8], ['query']),
    'get_owner' : IDL.Func([], [IDL.Principal], ['query']),
    'get_power' : IDL.Func([], [IDL.Float64], ['query']),
    'get_staking_point' : IDL.Func([], [IDL.Float64], ['query']),
    'get_withdraw_delay' : IDL.Func([], [IDL.Nat64], ['query']),
    'get_withdraw_fee' : IDL.Func([], [IDL.Nat64], ['query']),
    'transfer_owner' : IDL.Func([IDL.Principal], [], []),
    'update_staking' : IDL.Func([IDL.Nat64, IDL.Float64, IDL.Nat64], [], []),
    'withdraw_token' : IDL.Func([], [Result_1], []),
  });
};
export const init = ({ IDL }) => { return [IDL.Principal]; };
