export const idlFactory = ({ IDL }) => {
  const MinerInfo = IDL.Record({
    'power' : IDL.Float64,
    'miner_id' : IDL.Principal,
  });
  return IDL.Service({
    'dashboard' : IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Nat64, IDL.Nat64, IDL.Nat64))],
      ['query'],
    ),
    'get_mined_blocks' : IDL.Func([IDL.Principal], [IDL.Nat32], ['query']),
    'top_miners' : IDL.Func([], [IDL.Vec(MinerInfo)], ['query']),
    'update_dashboard' : IDL.Func(
      [IDL.Vec(IDL.Tuple(IDL.Nat64, IDL.Nat64, IDL.Nat64))],
      [],
      [],
    ),
    'update_mined_blocks' : IDL.Func(
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat32))],
      [],
      [],
    ),
    'update_top_miners' : IDL.Func([IDL.Vec(MinerInfo)], [], []),
  });
};
export const init = ({ IDL }) => { return []; };
