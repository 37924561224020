import "./index.scss"
import React from 'react';
import {CommonButton} from "../Button";

const Modal = ({isOpen, children, setOpen,onOk}: { isOpen: boolean, children: any, setOpen: Function,onOk:Function }) => {
    // if (!isOpen) return null;

    const onClose = () => {
        setOpen(false)
    }

    return (
        <div className="modal">
            <div className="modal_overlay" onClick={onClose}/>
            <div className="modal_content" onClick={e => e.stopPropagation()}>
                {children}
                <div className={"modal_foot"}>
                    <CommonButton text={"Confirm"} style={{padding: "5px 10px"}} handleClick={onOk}
                                  needLoading={false}/>
                    <CommonButton text={"Cancel"} style={{padding: "5px 10px"}} handleClick={onClose}
                                  needLoading={false}/>
                </div>
            </div>
        </div>
    );
};

export default Modal;
