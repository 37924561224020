import React, {useEffect} from 'react';
import './App.scss';
import {Header} from "./Components/Header";
import {NavPanel} from "./Components/NavPanel";
import {Home} from "./Components/Home";
import {Routes, Route, Navigate} from "react-router-dom";
import {Mining} from "./Components/Mining";
import {Treasury} from "./Components/Treasury";
import {useInit} from "./utils/useInit";
import {NotFound} from "./Components/NotFound";
import {Loading} from "./Components/Button";
import {Staking} from "./Components/Staking";
import {mainApi} from "./api/main";
import {updateMiners} from "./redux/features/miners";
import {Dashboard} from "./Components/Dashboard";
import {swapApi} from "./api/icpswap";
import {getPoolApi} from "./api/pool";
import {dashboardApi} from "./api/dashboard";
import {updateDashboard} from "./redux/features/dashboard";
import {Roadmap} from "./Components/Roadmap";


function App() {
  const {isAuth, principal} = useInit()

  const getPrice = async (): Promise<number> => {
    try {
      const res = await swapApi.getPool({
        fee: BigInt(3000),
        token0: {address: "ypkpu-uaaaa-aaaam-adihq-cai", standard: "ICRC2"},
        token1: {address: "ryjl3-tyaaa-aaaaa-aaaba-cai", standard: "ICP"}
      })
      const cid = res.canisterId
      const api = getPoolApi(cid.toString())
      const Res = await Promise.all([await api.quote(), fetch("https://coins.llama.fi/prices/current/coingecko:internet-computer")])
      const [icps, response] = Res
      const json = await response.json()
      const icp = json.coins["coingecko:internet-computer"].price
      return (Number(icps) / 1e8) * icp
    } catch (e) {
      console.error(e)
      return 0
    }
  }

  useEffect(() => {
    const init = () => principal && mainApi.initState(principal)
    init()
    const interval = setInterval(() => {
      init()
    }, 1000 * 35)
    return () => clearInterval(interval)
  }, [principal]);

  useEffect(() => {
    const init = () => getPrice().then(e => updateDashboard({icpsPrice: e}))
    init()
    const interval = setInterval(() => {
      init()
    }, 1000 * 25)
    return () => clearInterval(interval)
  }, []);

  useEffect(() => {
    const init = () => {
      dashboardApi.dashboard().then(e => updateDashboard({dashboard: e}))
      dashboardApi.top_miners().then(e => updateDashboard({topMiners: e}))
    }
    init()
    const interval = setInterval(() => {
      init()
    }, 1000 * 85)
    return () => clearInterval(interval)
  }, []);


  useEffect(() => {


    const init = () => {
      mainApi.get_blockchain_info().then(e => updateDashboard({blockChain: e}))
      mainApi.get_global_miners_number().then(e => updateDashboard({globalMinersNumber: Number(e)}))
      mainApi.get_staking_power().then(e => updateDashboard({stakingWeight: e}))
      mainApi.get_all_users().then(e => updateDashboard({users: e}))
      mainApi.get_lottery_users().then(e => updateDashboard({lotteryUsers: e}))
    }
    init()
    const interval = setInterval(() => {
      init()
    }, 10 * 1000)
    return () => clearInterval(interval)
  }, []);


  return (
    <div className="App">
      <Header/>
      <NavPanel/>
      <Routes>
        <Route path="/" element={<Navigate to={"/home"}/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/mining"
               element={isAuth === undefined ? <LoadingWrap/> : isAuth ? <Mining/> :
                 <Navigate to={"/home"}/>}/>
        <Route path="/staking" element={isAuth === undefined ? <LoadingWrap/> : isAuth ? <Staking/> :
          <Navigate to={"/home"}/>}/>
        <Route path="/vault" element={isAuth === undefined ? <LoadingWrap/> : isAuth ? <Treasury/> :
          <Navigate to={"/home"}/>}/>
        <Route path="/roadmap" element={<Roadmap/>}/>
        <Route path={"*"} element={<NotFound/>}/>
      </Routes>
    </div>
  );
}

const LoadingWrap = React.memo(() => {
  return <div style={{width: '100%', display: "flex", justifyContent: "center"}}>
    <Loading show={true}/>
  </div>
})


export default App;
