import * as SHA1 from "@dfinity/principal/lib/esm/utils/sha224";
import {getCrc32} from "@dfinity/principal/lib/esm/utils/getCrc";
import {Principal} from "@dfinity/principal"
import {Buffer} from "buffer";

export const getToAccountIdentifier = (principal: Principal, s?: Principal) => {
  const padding = new Buffer("\x0Aaccount-id");
  let k = s ? getPrincipalSubAccountArray(s) : new Array(32).fill(0)
  const array = new Uint8Array([
    //@ts-ignore
    ...padding,
    //@ts-ignore
    ...principal.toUint8Array(),
    ...k,
  ]);
  const hash = SHA1.sha224(array);
  const checksum = to32bits(getCrc32(hash));
  // @ts-ignore
  const array2 = new Uint8Array([...checksum, ...hash]);
  return toHexString(array2);
};
const getPrincipalSubAccountArray = (principal: Principal) => {
  const p = Array.from(principal.toUint8Array());
  let tmp = Array(1).fill(p.length).concat(p);
  while (tmp.length < 32) tmp.push(0);
  return tmp;
};

const to32bits = (num: number) => {
  let b = new ArrayBuffer(4);
  new DataView(b).setUint32(0, num);
  return Array.from(new Uint8Array(b));
};
const toHexString = (byteArray: Uint8Array) => {
  return Array.from(byteArray, function (byte) {
    return ("0" + (byte & 0xff).toString(16)).slice(-2);
  }).join("");
};

export function shortenString(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substring(0, maxLength / 2) + '...' + str.substring(str.length - maxLength / 2);
  }
}

export const getUint8ArrayFromHex = (str: string): Uint8Array => {
  return Uint8Array.from(Buffer.from(str, "hex"));
};

export function removeDuplicatesAndCount(arr: string[]) {
  const countMap = new Map();

  arr.forEach(item => {
    if (countMap.has(item)) {
      countMap.set(item, countMap.get(item) + 1);
    } else {
      countMap.set(item, 1);
    }
  });

  return Array.from(countMap, ([key, value]) => ({item: key, count: value}));
}

export function timeDifferenceFromNow(nanoseconds:number) {
  const milliseconds = nanoseconds / 1e6;
  const now = Date.now();

  const diffInMillis = now - milliseconds;

  let totalSeconds = Math.abs(Math.floor(diffInMillis / 1000));

  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${hours}h:${minutes}m:${seconds}s`;
}

export const chunkArray = <T>(arr:T[], size:number):T[][]=> {
  return arr.reduce((acc:T[][], _, i) => {
    if (i % size === 0) acc.push(arr.slice(i, i + size));
    return acc;
  }, []);
}