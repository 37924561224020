import {getActor} from "../utils/Actor";
import {idlFactory} from "../did/pool/pool.did.js";
import {Result, SwapArgs} from "../did/pool/pool";


class Pool {
  private readonly cid: string

  constructor(cid: string) {
    this.cid = cid
  }

  async getActor(cid: string) {
    return await getActor.createActor(
      idlFactory,
      this.cid
    );
  }

  async getNoIdentityActor() {
    return await getActor.noIdentityActor(
      idlFactory,
      this.cid
    );
  }

  async quote(): Promise<bigint> {
    const actor = await this.getNoIdentityActor()
    try {
      const args: SwapArgs = {
        zeroForOne: false,
        amountIn: "100000000",
        amountOutMinimum: "0"
      }
      const res = await actor.quote(args) as Result
      if ("ok" in res) {
        return res.ok
      }
      throw new Error(Object.keys(res.err)[0])
    } catch (e) {
      throw e
    }

  }


}


export const getPoolApi = (cid: string) => new Pool(cid);
