import {getActor} from "../utils/Actor";
import {idlFactory} from "../did/main/main.did";
import {Block, Blockchain, Result, Result_1, Tokens} from "../did/main/main";
import {Principal} from "@dfinity/principal";
import {updateMiners} from "../redux/features/miners";
import {minerApi} from "./miner";
import {tokenApi} from "./token";
import {requestInterceptor} from "./index";

export const main_cai = "2co3e-qyaaa-aaaao-qaaja-cai"

export type newError = "out of cycles" | "ICP subnet error"

export class MainCai {

  private static async getActor() {
    return await getActor.createActor(idlFactory, main_cai);
  }

  private static async getNoIdentityActor() {
    return await getActor.noIdentityActor(idlFactory, main_cai);

  }

  @requestInterceptor
  async create_miner(referCode: string | undefined) {
    const actor = await MainCai.getActor()
    try {
      const res = await actor.create_miner(referCode ? [referCode] : []) as Result
      console.log("create_miner", res)
      if ("Ok" in res) {
        return res.Ok.toString()
      }
      throw new Error(res.Err)
    } catch (e) {
      console.log("create_miner", e)
      throw e
    }
  }

  static async handlePromises<T>(promises: Promise<T>[], key?: string): Promise<(T | newError)[]> {
    const e = await Promise.allSettled(promises)
    const Res: (T | newError)[] = e.map((v, k) => {
      if (v.status === "fulfilled") {
        return v.value
      } else {
        if (typeof v.reason === "string" && v.reason?.includes("out of cycles")) {
          return "out of cycles";
        }
        return "ICP subnet error"
      }
    })
    key && updateMiners({[key]: Res})
    return Res
  }

  initState(who: Principal) {
    return new Promise<Principal[]>(async (resolve, reject) => {
      try {
        const miners = await this.get_miner_list(who)
        const cyclePromises: Promise<number>[] = []
        const blockPromises: Promise<number>[] = []
        const powerPromises: Promise<number>[] = []
        const stakingBalancesPromises: Promise<number>[] = []
        const isNewPromise: Promise<boolean>[] = []
        const stakingPowerPromises: Promise<number>[] = []
        updateMiners({miners})
        miners.forEach(e => {
          const miner = minerApi(e.toString())
          cyclePromises.push(miner.cycle_balance())
          blockPromises.push(miner.get_mined_block_height())
          powerPromises.push(miner.get_power())
          stakingBalancesPromises.push(tokenApi.icrc1_balance_of(e))
          isNewPromise.push(miner.isNew())
          stakingPowerPromises.push(miner.get_staking_point())
        })
        resolve(miners)
        MainCai.handlePromises(cyclePromises, "cycleBalance")
        MainCai.handlePromises(blockPromises, "blockMined")
        MainCai.handlePromises(powerPromises, "power")
        MainCai.handlePromises(stakingBalancesPromises, "stakingBalances")
        MainCai.handlePromises(isNewPromise, "isNew")
        MainCai.handlePromises(stakingPowerPromises, "stakingPoints")
      } catch (e: any) {
        reject(e.message)
      }
    })
  }

  async get_miner_list(who: Principal) {
    const actor = await MainCai.getNoIdentityActor();
    try {
      const res = await actor.get_miner_list(who) as Principal[]
      // const arr: Promise<any>[] = []
      // res.map(v => arr.push(this.upgrade_miner(v)))
      // Promise.allSettled(arr).then(e => console.log(e))
      return res
    } catch (e) {
      console.log("get_miner_lis", e)
      throw e
    }
  }

  async get_blockchain_info() {
    const actor = await MainCai.getNoIdentityActor()
    try {
      return await actor.get_blockchain_info() as Blockchain
    } catch (e) {
      console.log("get_blockchain_info", e)
      throw e
    }
  }

  async get_staking_power() {
    const actor = await MainCai.getNoIdentityActor()
    try {
      return await actor.get_staking_power() as number
    } catch (e) {
      console.log("get_staking_power", e)
      throw e
    }
  }

  async get_global_miners_number() {
    const actor = await MainCai.getNoIdentityActor()
    try {
      return await actor.get_global_miners_number() as bigint
    } catch (e) {
      console.log("get_global_miners_number", e)
      throw e
    }
  }

  async get_blocks(startHeight: bigint) {
    const actor = await MainCai.getNoIdentityActor()
    try {
      return await actor.get_blocks(startHeight) as Block[]
    } catch (e) {
      console.log("get_blocks", e)
      throw e
    }
  }

  @requestInterceptor
  async upgrade_miner(cid: Principal) {
    const actor = await MainCai.getActor()
    try {
      await actor.upgrade_miner(cid)
    } catch (e) {
      console.log("upgrade_miner", e)
      throw e
    }
  }

  @requestInterceptor
  async withdraw_icp(to: string, amount: bigint) {
    const actor = await MainCai.getActor()
    try {
      const token: Tokens = {
        e8s: amount
      }
      const res = await actor.withdraw_icp(to, token) as Result_1
      if ("Ok" in res) {
        return res.Ok
      }
      throw new Error(res.Err)
    } catch (e: any) {
      throw e
    }
  }

  get_lottery_users() {
    return new Promise<Principal[]>(async (resolve, reject) => {
      const actor = await MainCai.getNoIdentityActor()
      try {
        const res = await actor.get_lottery_users() as Principal[]
        return resolve(res)
      } catch (e: any) {
        reject(e.message)
      }
    })
  }

  @requestInterceptor
  old_for_new(miner: Principal) {
    return new Promise(async (resolve, reject) => {
      try {
        const actor = await MainCai.getActor()
        const res = await actor.old_for_new(miner) as Result_1
        console.log(res)
        if ("Ok" in res) {
          resolve(res.Ok)
        } else {
          reject(res.Err)
        }
      } catch (e) {
        console.log(e)
        reject(e)
      }
    })
  }


  get_all_users() {
    return new Promise<Principal[]>(async (resolve, reject) => {
      try {
        const actor = await MainCai.getNoIdentityActor()
        const res = await actor.get_all_users() as Principal[]
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })

  }

  register_referral_code() {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const actor = await MainCai.getActor()
        const res = await actor.register_referral_code() as string
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })
  }

  get_referral_code(who: Principal) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const actor = await MainCai.getNoIdentityActor()
        const res = await actor.get_referral_code(who) as string
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })
  }

  get_referral_users(code: string) {
    return new Promise<Principal[]>(async (resolve, reject) => {
      try {
        const actor = await MainCai.getNoIdentityActor()
        const res = await actor.get_referral_users(code) as Principal[]
        resolve(res)
      } catch (e) {
        reject(e)
      }
    })

  }

}

export const mainApi = new MainCai()
