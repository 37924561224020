import React from 'react';
import ReactECharts from 'echarts-for-react';
import {useDashboardStore} from "../../redux/features/dashboard";

const color = "rgb(213,209,209)"
const baseColor = '#679354';

const adjustBrightness = (color: string, amount: number) => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

const HorizontalBarChart = () => {

  const {dashboard} = useDashboardStore()
  const [labels, values, colors] = React.useMemo(() => {
    if (!dashboard|| dashboard.length === 0) return [[], [], []]
    const lables = dashboard.map(([min, max, value]) => {
      return `${Number(min)} - ${Number(max)}`
    })
    const values = dashboard.map(([min, max, value]) => {
      return Number(value)
    })
    const colors = dashboard.map((v, k) => {
      return adjustBrightness(baseColor, (k - 1.5) * 20)
    })
    return [lables, values, colors]
  }, [dashboard])

  const getOption = () => {
    if (values.length === 0) {
      return {}; //
    }
    return {
      tooltip: {},
      grid: {
        top: '0%', //
        left: '0%',
        bottom: '0%',
        containLabel: true //
      },
      xAxis: {
        type: 'log',
        axisLine: {
          show: false //
        },
        splitLine: {
          show: false //
        },
        axisTick: {
          show: false //
        },
        axisLabel: {
          show: false,
        }
      },
      yAxis: {
        type: 'category',
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false //
        },
        axisLabel: {
          textStyle: {
            color,
            fontFamily: "modern"
          }
        },
        data: labels
      },
      series: [{
        type: 'bar',
        data: values,
        itemStyle: {
          barBorderRadius: [6],
          color: (params: any) => {
            return colors[params.dataIndex]
          },
        },
        label: {
          show: true,
          position: 'right',
          textStyle: {
            color,
            fontSize: 12,
            fontWeight: "normal",
            fontFamily: "modern"

          }
        }
      }]
    };
  };
  return (
    <ReactECharts
      option={getOption()}
      style={{width: '100%'}}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  );
};

export default HorizontalBarChart;
