import React, {createContext, useContext, useEffect, useState} from "react";
import {authClient, IIForIdentity} from "./IIForIdentity";
import {Principal} from "@dfinity/principal";
import {getToAccountIdentifier} from "./util";
import {main_cai, mainApi} from "../api/main";
import {updateMiners} from "../redux/features/miners";

export interface Props {
  readonly principal: Principal | undefined;
  readonly logOut: Function | undefined;
  readonly logIn: Function | undefined;
  readonly isAuth: boolean | undefined;
  readonly account?: string
  readonly loading: boolean
  readonly setLoading: Function
  readonly refresh: Function
  readonly referCode: string | undefined
}

export const useProvideAuth = (authClient: IIForIdentity): Props => {
  const [isAuthClientReady, setAuthClientReady] = useState(false);
  const [principal, setPrincipal] = useState<Principal | undefined>(undefined);
  const [isAuth, setIsAuth] = useState<boolean | undefined>();
  const [account, setAccount] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [referCode, setReferCode] = useState<string | undefined>(undefined)

  if (!isAuthClientReady) authClient.create().then(() => setAuthClientReady(true));

  const init = async () => {
    const [identity, isAuthenticated] = await Promise.all([
      authClient.getIdentity(),
      authClient.isAuthenticated(),
    ])
    if (!isAuthenticated) return setIsAuth(false)
    const principal = identity?.getPrincipal() as Principal | undefined;
    setPrincipal(principal);
    setIsAuth(true);
  }

  useEffect(() => {
    isAuthClientReady && init().then()
  }, [isAuthClientReady]);

  useEffect(() => {
    if (principal && isAuth) {
      const initReferCode = async () => {
        try {
          const res = await mainApi.get_referral_code(principal)
          if (!res) {
            const code = await mainApi.register_referral_code()
            setReferCode(code)
          } else {
            setReferCode(res)
          }
        } catch (e) {
          console.log(e)
        }
      }
      initReferCode()
    }
  }, [principal, isAuth]);

  useEffect(() => {
    if (principal && isAuth) {
      const subAccountId = getToAccountIdentifier(Principal.from(main_cai), principal)
      setAccount(subAccountId)
    }
  }, [principal, isAuth])

  const logIn = async (): Promise<{ message?: string; status?: number } | undefined> => {
    try {
      if (!authClient) return {message: "connect error"};
      const identity = await authClient.login();
      const principal = identity.getPrincipal();
      setPrincipal(principal);
      if (identity) {
        setIsAuth(true);
      } else {
        setIsAuth(false)
        return {message: "connect error"};
      }
    } catch (e) {
      console.warn(e)
    }
  };

  const logOut = async (): Promise<void> => {
    await authClient.logout();
    setIsAuth(false);
  };

  const refresh = () => {
    updateMiners({
      miners: undefined,
      cycleBalance: undefined,
      blockMined: undefined,
      power: undefined,
      stakingBalances: undefined
    })
    principal && mainApi.initState(principal)
  }


  return {
    principal,
    logIn,
    logOut,
    isAuth,
    account, loading, setLoading,
    refresh,
    referCode
  };
}

const props: Props = {
  principal: undefined,
  logIn: undefined,
  logOut: undefined,
  isAuth: undefined,
  account: undefined,
  loading: false, setLoading: () => {
  },
  refresh: () => {
  },
  referCode: undefined
}

const authContext = createContext(props);

export function ProvideAuth({children}: any) {
  const auth = useProvideAuth(authClient);
  return (
    <authContext.Provider value={Object.assign(auth)}>
      {children}
    </authContext.Provider>
  );
}

export const useInit = () => {
  return useContext(authContext);
};
