import {getActor} from "../utils/Actor";
import {idlFactory} from "../did/token/token.did";
import {Principal} from "@dfinity/principal";
import {Account, Result, TransferArg} from "../did/token/token";
import {requestInterceptor} from "./index";

const token = "ypkpu-uaaaa-aaaam-adihq-cai";

class Token {
  async getActor() {
    return await getActor.createActor(
      idlFactory,
      token
    );
  }

  async getNoIdentityActor() {
    return await getActor.noIdentityActor(
      idlFactory,
      token
    );
  }

  icrc1_balance_of(who: Principal) {
    return new Promise<number>(async (resolve, reject) => {
      const actor = await this.getNoIdentityActor()
      try {
        const account: Account = {
          owner: who,
          subaccount: []

        }
        const res = await actor.icrc1_balance_of(account) as bigint
        resolve(Number(res))
      } catch (e: any) {
        reject(e.message)
      }
    })
  }

  @requestInterceptor
  async icrc1_transfer(to: string, amount: bigint) {
    try {
      const actor = await this.getActor()
      const arg: TransferArg = {
        to: {
          owner: Principal.fromText(to),
          subaccount: []
        },
        fee: [],
        memo: [],
        from_subaccount: [],
        created_at_time: [],
        amount
      }
      console.log(arg)
      const res = await actor.icrc1_transfer(arg) as Result
      console.log("icrc1_transfer res", res)
      if ("Ok" in res) {
        return res.Ok
      }
      throw new Error(Object.keys(res.Err)[0])
    } catch (e) {
      throw e
    }
  }


}


export const tokenApi = new Token();
