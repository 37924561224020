import {message} from "antd";

export function requestInterceptor(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  descriptor.value = async function (...args: any[]) {
    try {
      const result = await originalMethod.apply(this, args);
      return result
    } catch (error: any) {
      if (JSON.stringify(error).includes("Specified sender delegation has expired"))
        message.info("Login expired, please refresh the page and try again")
      throw error
    }
  };
  return descriptor;
}
