import {createSlice} from "@reduxjs/toolkit"
import {RootState} from "../store"
import {useAppSelector} from "../hook"
import {MinerInfo} from "../../did/dashboard/dashboard";
import {Blockchain} from "../../did/main/main";
import {Principal} from "@dfinity/principal";


type Dashboard = {
  dashboard?: Array<[bigint, bigint,bigint]>
  topMiners?: Array<MinerInfo>
  icpsPrice?: number
  blockChain?: Blockchain
  globalMinersNumber?: number,
  stakingWeight?: number
  users?: Principal[]
  lotteryUsers?: Principal[]

}

const initialState: Dashboard = {}
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    update: (state, action: { type: string, payload: Dashboard }) => {
      return {...state, ...action.payload}
    },
  },
})

const {update} = dashboardSlice.actions
const dashboard = (state: RootState) => state.dashboard
export const updateDashboard = async (result: Dashboard) => {
  const store = await (await import("../store")).default
  store.dispatch(update(result))
}
export const useDashboardStore = (): Dashboard => useAppSelector(dashboard)
export default dashboardSlice.reducer
