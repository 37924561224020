import React, {CSSProperties} from "react";
import "./index.scss"
import {useInit} from "../../utils/useInit";

type Props = {
  text: string, handleClick: Function, needLoading: boolean, style?: CSSProperties
}

export const CommonButton = React.memo((props: Props) => {
  const {text, handleClick, needLoading, style} = props
  const {loading} = useInit()
  return <div className={"common_button"} style={{...style}} onClick={() => {
    if (needLoading && loading) return 0
    handleClick()
  }}>
    <Loading show={needLoading && loading}/>
    {text}
  </div>
})

export const Loading = React.memo(({show,style}: { show: boolean,style?:CSSProperties }) => {
  return <svg style={{...style}} display={show ? "block" : "none"} className="loading" viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="4298" width="20px" height="20px">
    <path
      d="M512 170.666667C323.477333 170.666667 170.666667 323.477333 170.666667 512s152.810667 341.333333 341.333333 341.333333 341.333333-152.810667 341.333333-341.333333h85.333334c0 235.648-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333v85.333334z"
      p-id="4299" fill="var(--border-color)"></path>
  </svg>
})
