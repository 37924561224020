import {getActor} from "../utils/Actor";
import {idlFactory} from "../did/miner/miner.did";
import {Result} from "../did/miner/miner";
import {requestInterceptor} from "./index";
import {dashboardApi} from "./dashboard";
import {Principal} from "@dfinity/principal";

class Miner {
  private readonly cid: string

  constructor(cid: string) {
    this.cid = cid
  }

  async getActor() {
    return await getActor.createActor(idlFactory, this.cid);
  }

  private async getNoIdentityActor() {
    return await getActor.noIdentityActor(idlFactory, this.cid);
  }

  cycle_balance() {
    return new Promise<number>(async (resolve, reject) => {
      const actor = await this.getNoIdentityActor()
      try {
        const res = await actor.cycle_balance() as bigint
        resolve(Number(res))
      } catch (e: any) {
        console.log("cycle_balance", e)
        reject(e.message)
      }
    })
  }

  get_mined_block_height() {
    return new Promise<number>(async (resolve, reject) => {
      // const actor = await this.getNoIdentityActor()
      try {
        // const res = await actor.get_mined_block_height() as BigUint64Array | bigint[]
        const Res = await dashboardApi.get_mined_blocks(Principal.from(this.cid))
        resolve(Res)
      } catch (e: any) {
        console.log("get_mined_block_height", e)
        reject(e.message)
      }
    })
  }

  get_power() {
    return new Promise<number>(async (resolve, reject) => {
      const actor = await this.getNoIdentityActor()
      try {
        const res = await actor.get_power() as number
        resolve(res)
      } catch (e: any) {
        console.log("get_power", e)
        reject(e.message)
      }
    })
  }

  @requestInterceptor
  withdraw_token() {
    return new Promise<number>(async (resolve, reject) => {
      const actor = await this.getActor()
      try {
        const res = await actor.withdraw_token() as Result
        if ("Ok" in res) {
          return resolve(Number(res.Ok))
        }
        reject(res.Err)
      } catch (e: any) {
        console.log("withdraw_token", e)
        reject(e.message)
      }
    })
  }

  get_withdraw_delay() {
    return new Promise<number>(async (resolve, reject) => {
      const actor = await this.getNoIdentityActor()
      try {
        const res = await actor.get_withdraw_delay() as bigint
        resolve(Number(res))
      } catch (e: any) {
        reject(e.message)
      }
    })
  }

  isNew() {
    return new Promise<boolean>(async (resolve, reject) => {
      const actor = await this.getNoIdentityActor()
      try {
        const res = await actor.get_miner_version() as number
        if (res < 4) return resolve(false)
        return resolve(true)
      } catch (e: any) {
        if (e?.message?.includes("has no query method"))
          return resolve(false)
        reject(e.message)
      }
    })
  }

  get_staking_point() {
    return new Promise<number>(async (resolve, reject) => {
      try {
        const actor = await this.getNoIdentityActor()
        const res = await actor.get_staking_point() as bigint
        resolve(Number(res))
      } catch (e) {
        reject(e)
      }
    })
  }

  get_withdraw_fee() {
    return new Promise<number>(async (resolve, reject) => {
      try {
        const actor = await this.getNoIdentityActor()
        const res = await actor.get_withdraw_fee() as bigint
        resolve(Number(res))
      } catch (e: any) {
        if (e?.message?.includes("has no query method"))
          return resolve(-1)
        reject(e)
      }
    })
  }

}

export const minerApi = (cid: string) => new Miner(cid)
